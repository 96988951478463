import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import { Provider } from "react-redux";
import { theme } from "samba-ui";
import { ThemeProvider } from "styled-components";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./store";
import AppWrapper from "./AppWrapper";

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID } = process.env;

const Root = () => {
  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      audience="https://api.currency.samba.tv"
      scope="openid profile"
    >
      <Provider store={store()}>
        <ThemeProvider theme={theme.v2}>
          <AppWrapper />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
